import { Elm } from './src/Main.elm';
const org = require('org');
const purecss = require('purecss');
import './index.css';

const app = Elm.Main.init();
const c = console;

app.ports.putprotl.subscribe(function(data) {
  sessionStorage.setItem('protocollisting', data);
});

app.ports.requestprotl.subscribe(function() {
  var data = sessionStorage.getItem('protocollisting');

  if (data === null) {
    data = "";
  }
  
  app.ports.receivedprotl.send(data);
});

app.ports.putgl.subscribe(function(data) {
  sessionStorage.setItem('generalnoteslisting', data);
});

app.ports.requestgl.subscribe(function() {
  var data = sessionStorage.getItem('generalnoteslisting');

  if (data === null) {
    data = "";
  }
  
  app.ports.receivedgl.send(data);
});

app.ports.puttxtl.subscribe(function(data) {
  sessionStorage.setItem('textlisting', data);
});

app.ports.requesttxtl.subscribe(function() {
  var data = sessionStorage.getItem('textlisting');

  if (data === null) {
    data = "";
  }
  
  app.ports.receivedtxtl.send(data);
});

app.ports.puttl.subscribe(function(data) {
  sessionStorage.setItem('translationlisting', data);
});

app.ports.requesttl.subscribe(function() {
  var data = sessionStorage.getItem('translationlisting');

  if (data === null) {
    data = "";
  }
  
  app.ports.receivedtl.send(data);
});

app.ports.puttoken.subscribe(function(data) {
  localStorage.setItem('token', data);
});

app.ports.requesttoken.subscribe(function() {
  var data = localStorage.getItem('token');

  if (data === null) {
    data = "";
  }
  
  app.ports.receivedtoken.send(data);
});

app.ports.renderquest.subscribe(function(data) {
  // Parse the org data
  var parser = new org.Parser();
  var orgDocument = parser.parse(data);
  var orgHTMLDocument = orgDocument.convert(org.ConverterHTML);
  var htmlString = orgHTMLDocument.toString();

  // Now convert it to a dom object for manipulation
  var domparser = new DOMParser();
  var doc = domparser.parseFromString(htmlString, "text/html");
  var XMLS = new XMLSerializer();

  // Remove the table of contents
  doc.querySelector('ul').remove();

  // Get the nonstandard title produced by the parser library
  var h = doc.getElementsByTagName('h1')[0];

  // Remove it if nothing was there. Otherwise make it a paragraph.
  if (h.innerHTML == "Untitled") {
    h.remove();
  } else {
    var p = doc.createElement('p');
    p.innerHTML = h.innerHTML;
    h.parentNode.replaceChild(p, h);
  }

  // If a link is to an image, replace the link with an img
  Array.from(doc.getElementsByTagName('a')).forEach(x => {
    var pattern = /(\.png|\.jpg)$/;
    var url = x.getAttribute('href');
    var title = x.innerHTML;

    if (url.match(pattern)) {
      var img = doc.createElement('img');
      img.setAttribute('src', url);
      img.setAttribute('title', title);
      x.parentNode.replaceChild(img, x);
    }
  });
  
  app.ports.renderedquest.send(doc.querySelector('body').innerHTML);
});

app.ports.rendernote.subscribe(function(rendering) {
  var rend = JSON.parse(rendering);
  var data = rend.content;
  
  // Parse the org data
  var parser = new org.Parser();
  var orgDocument = parser.parse(data);
  var orgHTMLDocument = orgDocument.convert(org.ConverterHTML);
  var htmlString = orgHTMLDocument.toString();

  // Now convert it to a dom object for manipulation
  var domparser = new DOMParser();
  var doc = domparser.parseFromString(htmlString, "text/html");
  var XMLS = new XMLSerializer();

  // Remove the table of contents
  doc.querySelector('ul').remove();

  // Get the nonstandard title produced by the parser library
  var h = doc.getElementsByTagName('h1')[0];

  // Remove it if nothing was there. Otherwise make it a paragraph.
  if (h.innerHTML == "Untitled") {
    h.remove();
  } else {
    var p = doc.createElement('p');
    p.innerHTML = h.innerHTML;
    h.parentNode.replaceChild(p, h);
  }

  // If a link is to an image, replace the link with an img
  Array.from(doc.getElementsByTagName('a')).forEach(x => {
    var pattern = /(\.png|\.jpg)$/;
    var url = x.getAttribute('href');
    var title = x.innerHTML;

    if (url.match(pattern)) {
      var img = doc.createElement('img');
      img.setAttribute('src', url);
      img.setAttribute('title', title);
      x.parentNode.replaceChild(img, x);
    }
  });

  rend.content = doc.querySelector('body').innerHTML;
  
  app.ports.renderednote.send(JSON.stringify(rend));
});

app.ports.requestconfig.subscribe(function(data) {
  var dbhost = process.env.DB_HOST;
  var proto = process.env.PROTO;
  var config_json = JSON.stringify({dbhost: dbhost,
                                    proto: proto
                                   });

  app.ports.receivedconfig.send(config_json);
});
